import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '@/product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, SITE_NAME } from '@/utils'
import { mapData } from '@/product/common/components'
import { Link } from 'react-router-dom'

const Footer = () => {
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'Subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footCnt">
                <div className="customContainer footInnerCnt">
                    <div className="footLinks">
                        <h4>Site Services</h4>
                        <ul>
                            <li>
                                <Link to="/terms_of_use" id="footer_terms_of_use">
                                    Terms of use
                                </Link>
                            </li>
                            <li>
                                <a
                                    href="https://www.debeersgroup.com/site-services/cookies"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_cookies"
                                >
                                    Cookies
                                </a>
                            </li>
                            <li>
                                <Link to="/privacy_policy" id="footer_privacy_policy">
                                    Privacy Policy
                                </Link>
                            </li>

                            <li>
                                <Link to="/support" id="footer_support">
                                    FAQ
                                </Link>
                            </li>
                            {/* <li>
                                <a
                                    href="https://www.debeersgroup.com/site-services/accessibility"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_accessibility"
                                >
                                    Accessibility
                                </a>
                            </li> */}
                            <li>
                                <a
                                    href="https://www.debeersgroup.com/site-services/uk-msa"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_ukmodernslaveryact"
                                >
                                    UK Modern Slavery Act
                                </a>
                            </li>

                            {/* <li>
                                <a
                                    href="https://www.debeersgroup.com/site-services/site-map"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_sitemap"
                                >
                                    Site Map
                                </a>
                            </li> */}
                        </ul>
                    </div>

                    <div className="footLinks">
                        <h4>Group Websites</h4>
                        <div className="siteServices">
                            <ul>
                                <li>
                                    <a
                                        href="https://www.debeersgroup.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_canada"
                                    >
                                        De Beers Group
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="http://www.debeersgroup.com/about-us/our-operations/sales"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_group_auctions"
                                    >
                                        De Beers Group Auctions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.debeers.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_jewellers"
                                    >
                                        De Beers London
                                    </a>
                                </li>
                                {/* <li>
                                    <a
                                        href="http://www.forevermark.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_forevermark"
                                    >
                                        De Beers Forevermark
                                    </a>
                                </li> */}

                                <li>
                                    <a
                                        href="http://institute.debeers.com/en-gb"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_instituteofdiamonds"
                                    >
                                        De Beers Institute of Diamonds
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="http://gss.debeersgroup.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_sightholder_sales"
                                    >
                                        Global Sightholder Sales
                                    </a>
                                </li>
                                {/* <li>
                                    <a
                                        href="https://ignite.debeersgroup.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_igniterm"
                                    >
                                        De Beers Group IGNITETM
                                    </a>
                                </li> */}

                                {/* <li>
                                    <a
                                        href="https://lightboxjewelry.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_lightbox"
                                    >
                                        Lightbox Jewelry
                                    </a>
                                </li> */}
                            </ul>
                            <ul>
                                <li>
                                    <a
                                        href="http://www.e6.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_elementsix"
                                    >
                                        Element Six
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.tracr.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_tracr"
                                    >
                                        Tracr
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://gemfair.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_gemfair"
                                    >
                                        GemFair
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.diamondeducation.debeers.com/en-GB/home"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_diamond_education"
                                    >
                                        Diamond Education
                                    </a>
                                </li>

                                {/* <li>
                                    <a
                                        href="https://canada.debeersgroup.com"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_canada"
                                    >
                                        De Beers Canada
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a
                                        href="https://www.debeers.co.uk/en-gb/de-beers-diamond-route.html#:~:text=Our%20aim%20is%20that%20through%20the%20Diamond%20Route,%20we%20can"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_route"
                                    >
                                        Diamond Route
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a
                                        href="https://www.debeersgroup.com/code-of-origin"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_codeoforigin"
                                    >
                                        Code of Origin
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="footLinks">
                        <h4>Connect with us</h4>
                        <ul className="footSocial">
                            <li>
                                <a
                                    href="https://twitter.com/Debeersgroup"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_twitter"
                                >
                                    <img src="/assets/svg/tw.svg" alt="Twitter Icon" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/DeBeersGroupOfCompanies"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_facebook"
                                >
                                    <img src="/assets/svg/fb.svg" alt="Facebook Icon" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/debeersgroup/"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_instagram"
                                >
                                    <img src="/assets/svg/ig.svg" alt="Instagram Icon" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href=" https://www.linkedin.com/company/debeersgroupsauctions"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_linkedin"
                                >
                                    <img src="/assets/svg/ln.svg" alt="Linkedin Icon" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/user/TDBGoC"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="footer_youtube"
                                >
                                    <img src="/assets/svg/yt.svg" alt="Youtube Icon" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
